import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class ApiBaseService {
  public readonly apiUrl: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {
    this.apiUrl = this.environmentService.getApiBaseUrl();
  }

  public post<Model>(
    path: string,
    // tslint:disable-next-line:no-any
    request?: any,
    additionalHeaders?: HttpHeaders,
  ): Observable<Model> {
    if (!HttpHeaders) {
      return this.httpClient.post<Model>(`${this.getUrl(path)}`, request);
    } else {
      return this.httpClient.post<Model>(`${this.getUrl(path)}`, request, {
        headers: additionalHeaders,
      });
    }
  }

  public postAbsolutePath<Model>(path: string, params?: Params): Observable<Model> {
    return this.httpClient.post<Model>(path, params);
  }

  public put<Model>(
    path: string,
    // tslint:disable-next-line:no-any
    request?: any,
    additionalHeaders?: HttpHeaders,
  ): Observable<Model> {
    if (!HttpHeaders) {
      return this.httpClient.put<Model>(`${this.getUrl(path)}`, request, {
        headers: { 'Content-Type': 'application/json' },
      });
    } else {
      return this.httpClient.put<Model>(`${this.getUrl(path)}`, request, {
        headers: additionalHeaders,
      });
    }
  }

  public delete<Model>(path: string, request?: any): Observable<Model> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };

    return this.httpClient.delete<Model>(`${this.getUrl(path)}`, options);
  }

  public get<Model>(path: string): Observable<Model> {
    return this.httpClient.get<Model>(`${this.getUrl(path)}`);
  }

  public getWithParams<Model>(path: string, params: any): Observable<Model> {
    return this.httpClient.get<Model>(`${this.getUrl(path)}`, { params });
  }

  private getUrl(path: string): string {
    return `${this.apiUrl}/${path}`;
  }
}
