import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class EnvironmentService {
  constructor() {}

  public getApiBaseUrl(): string {
    //TODO: change the definition with variable
    // const isAdminPortal: boolean = location.origin.includes('admin');
    // const isAdminPortal = true;

    // if (isAdminPortal) {
    // 	return adminEnvironment.apiBaseUrl;
    // } else {
    // 	return fundoEnvironment.apiBaseUrl;
    // }

    return environment.apiBaseUrl;
  }
}
